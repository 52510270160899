<template>
  <v-container>
    <v-row class="matriz-row-container" justify="space-between">
      <v-col cols="4">
        <v-row justify="center" class="pl-15 mb-15">
          <h3>{{ nodeId ? `Mostrando datos de nodo ${nodeLabel}` : `Seleccione nodo para mostrar información` }}</h3>
        </v-row>
        <v-row justify="center" class="fill-height">
          <pucobre-matriz @node-id="getDeviceDisplayers" ></pucobre-matriz>
        </v-row>
      </v-col>
      <v-col cols="7" v-if="deviceDisplayers && nodeId">
        <device-data
          :displayers="deviceDisplayers"
          :data-background-color="getTheme"
          :nodeId="nodeId"
          class="monitoring-container"
        />
      </v-col>
    </v-row>
  </v-container>
  

</template>

<script>
import PucobreMatriz from '../components/Monitoring/Matriz/PucobreMatriz.vue';
import DeviceData from "../components/Devices/DeviceData";
import { mapGetters } from "vuex";
export default {
  components: {
    PucobreMatriz,
    DeviceData,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  data: () => ({
    deviceDisplayers: null,
    nodeId: null,
    nodeLabel: '',
    pucobreRowContainer: 500,
  }),
  methods: {
    getDeviceDisplayers(nodeId, nodeLabel) {
      console.log(nodeId);
      this.$store
        .dispatch("displayers/getDeviceDisplayers", nodeId)
        .then((data) => {
          this.deviceDisplayers = data;
          this.nodeId = nodeId;
          this.nodeLabel = nodeLabel;
        });
    },
  },
  mounted() {
    console.log(process.env.VUE_APP_API_URL);
  },
};
</script>
<style lang="sass" scoped>
.matriz-row-container
  height: 80vh
</style>