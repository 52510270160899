<template>
    <div class="sticky">
      <div class="matriz-container" v-if="loading">
        <div class="row">
          <div class="node">
            <div class="node-text">D4</div>
          </div>
          <div class="node">
            <div class="node-text">C4</div>
          </div>
          <div class="node">
            <div class="node-text" >B4</div>
          </div>
          <div class="node">
            <div class="node-text" >A4</div>
          </div>
        </div>
        <div class="row">
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
        </div>
        <div class="row">
          <div class="node">
            <div class="node-text">D3</div>
          </div>
          <div class="node">
            <div class="node-text">C3</div>
          </div>
          <div class="node">
            <div class="node-text">B3</div>
          </div>
          <div class="node">
            <div class="node-text">A3</div>
          </div>
        </div>
        <div class="row">
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
        </div>
        <div class="row">
          <div class="node">
            <div class="node-text" >D2</div>
          </div>
          <div class="node">
            <div class="node-text">C2</div>
          </div>
          <div class="node">
            <div class="node-text">B2</div>
          </div>
          <div class="node">
            <div class="node-text">A2</div>
          </div>
        </div>
        <div class="row">
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
        </div>
        <div class="row">
          <div class="node">
            <div class="node-text">D1</div>
          </div>
          <div class="node">
            <div class="node-text">C1</div>
          </div>
          <div class="node">
            <div class="node-text">B1</div>
          </div>
          <div class="node">
            <div class="node-text">A1</div>
          </div>
        </div>
        <div class="row">
          <div class="link">
            <div class="last-rope"></div>
          </div>
          <div class="link">
            <div class="last-rope"></div>
          </div>
          <div class="link">
            <div class="last-rope"></div>
          </div>
          <div class="link">
            <div class="last-rope"></div>
          </div>
        </div>
      </div>
      <div class="matriz-container" v-if="!loading">
        <div class="row">
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(70) ? 'node-online': 'node-offline']" @click="emitNodeId(70, 'D4')">D4</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(67) ? 'node-online': 'node-offline']" @click="emitNodeId(67, 'C4')">C4</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(68) ? 'node-online': 'node-offline']" @click="emitNodeId(68, 'B4')">B4</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(59) ? 'node-online': 'node-offline']" @click="emitNodeId(59, 'A4')">A4</div>
          </div>
        </div>
        <div class="row">
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
        </div>
        <div class="row">
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(63) ? 'node-online': 'node-offline']" @click="emitNodeId(63, 'D3')">D3</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(69) ? 'node-online': 'node-offline']" @click="emitNodeId(69, 'C3')">C3</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(64) ? 'node-online': 'node-offline']" @click="emitNodeId(64, 'B3')">B3</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(58) ? 'node-online': 'node-offline']" @click="emitNodeId(58, 'A3')">A3</div>
          </div>
        </div>
        <div class="row">
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
        </div>
        <div class="row">
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(61) ? 'node-online': 'node-offline']" @click="emitNodeId(61, 'D2')">D2</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(71) ? 'node-online': 'node-offline']" @click="emitNodeId(71, 'C2')">C2</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(66) ? 'node-online': 'node-offline']" @click="emitNodeId(66, 'B2')">B2</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(62) ? 'node-online': 'node-offline']" @click="emitNodeId(62, 'A2')">A2</div>
          </div>
        </div>
        <div class="row">
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
          <div class="link">
            <div class="rope"></div>
          </div>
        </div>
        <div class="row">
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(60) ? 'node-online': 'node-offline']" @click="emitNodeId(60, 'D1')">D1</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(57) ? 'node-online': 'node-offline']" @click="emitNodeId(57, 'C1')">C1</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(65) ? 'node-online': 'node-offline']" @click="emitNodeId(65, 'B1')">B1</div>
          </div>
          <div class="node">
            <div class="node-text" :class="[nodesStatus.get(72) ? 'node-online': 'node-offline']" @click="emitNodeId(72, 'A1')">A1</div>
          </div>
        </div>
        <div class="row">
          <div class="link">
            <div class="last-rope"></div>
          </div>
          <div class="link">
            <div class="last-rope"></div>
          </div>
          <div class="link">
            <div class="last-rope"></div>
          </div>
          <div class="link">
            <div class="last-rope"></div>
          </div>
        </div>
      </div>
      <div class="out-bound-link">
        <div class="row">
          <div class="link">
            <div class="rope-d"></div>
          </div>
          <div class="link">
            <div class="rope-c"></div>
          </div>
          <div class="link">
            <div class="rope-b"></div>
          </div>
          <div class="link">
            <div class="rope-a"></div>
          </div>
        </div>
        <div class="main-node">
          <div class="node"></div>
        </div>
        <div class="final-link">
          <div class="link-rope"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import api from '../../../services/device.api'
  export default {
    data: () => ({
      devices: [70, 67, 68, 59, 63, 69, 64, 58, 61, 71, 66, 62, 60, 57, 65, 72],
      nodesStatus: new Map(),
      loading: true
    }),
    methods: {
      emitNodeId(nodeId, nodeLabel) {
        this.$emit('node-id', nodeId, nodeLabel);
      },
      async getNodesStatus(){
        try {
          const { data } = await api.getMatrixNodesStatus(this.devices)
          if (data) {
            console.log(data);
            data.forEach(node => {
              this.nodesStatus.set(node.id, node.online)
              console.log(this.nodesStatus.get(node.id), `${node.id}`);
            });
          }
          this.loading= false
        } catch (error) {
          this.loading= false
          console.error(error);
        }
      }
    },
    mounted(){
      this.getNodesStatus()
    }
  };
  </script>
  
  <style lang="sass" scoped>
  .sticky
    top: 200px
    height: 500px
    padding-left: 60px
  .matriz-container
    border: 2px solid orange
    height: 350px
    width: 400px
    border-radius: 10px
    padding: 60px 40px 0 40px
    border-top: none
    border-top-right-radius: 0px
    border-top-left-radius: 0px
    
    .row
      display: flex
      flex-direction: row
      justify-content: space-around
      .node
        border: 1px solid black
        height: 40px
        width: 40px
        border-radius: 5px
        background-color: #A5A5A5
        &:hover
          background-color: #e5e5e5
          cursor: pointer
        display: flex
        justify-content: center
        align-items: center
        .node-text
          display: flex
          justify-content: center
          align-items: center
          height: 30px
          width: 30px
          color: white
          font-size: 16px
          font-weight: 400
          border: 1px solid white
      .node-online
        background-color: #23D623
      .node-offline
        background-color: #FF0000
      .link
        height: 30px
        width: 40px
        display: flex
        flex-direction: row
        justify-content: center
        .rope
          width: 4px
          background-color: black
        .last-rope
          width: 4px
          height: 70px
          background-color: black
  
  .out-bound-link
    border: 2px solid transparent
    height: 100px
    width: 400px
    padding: 6px 40px
    .row
      display: flex
      flex-direction: row
      justify-content: space-around
      .link
        height: 30px
        width: 60px
        display: flex
        flex-direction: row
        justify-content: center
        .rope-d
          height: 130px
          width: 4px
          background-color: black
          rotate: -70deg
          position: relative
          bottom: 23px 
          left: 60px
        .rope-c
          height: 60px
          width: 4px
          background-color: black
          rotate: -45deg
          position: relative
          top: 12px 
          left: 21px
        .rope-b
          height: 60px
          width: 4px
          background-color: black
          rotate: 45deg
          position: relative
          top: 12px 
          right: 21px
        .rope-a
          height: 130px
          width: 4px
          background-color: black
          rotate: 70deg
          position: relative
          bottom: 23px 
          right: 60px
  .main-node
    display: flex
    direction: row
    justify-content: center
    align-items: flex-end
    height: 80px
    .node
      position: relative
      z-index: 100
      border: 1px solid black
      height: 40px
      width: 40px
      border-radius: 5px
      background-color: #A5A5A5
  
  .final-link
    display: flex
    direction: row
    justify-content: center
    .link-rope
      height: 50px
      width: 4px
      background-color: black
  </style>